<template>
  <div class="case-container">
    <!-- navbar -->
    <qb-navbar title="案例展示"> </qb-navbar>
    <!-- title -->
    <qb-title title="案例展示" subtitle="Quanbang Group Case show"></qb-title>
    <!-- item list -->
    <case-item></case-item>
  </div>
</template>

<script>
import CaseItem from './components/case-item.vue'
export default {
  name: 'Case',
  components: {
    CaseItem
  }
}
</script>

<style lang="less" scoped></style>
