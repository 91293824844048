<template>
  <div class="case-item-container">
    <div
      v-for="(item, index) in caseList"
      :key="item.id"
      @click="$router.push(`/case/detail/${item.id}`)"
    >
      <template v-if="index % 2 === 0">
        <div class="left item">
          <div class="content">
            <h3 class="van-ellipsis title">{{ item.title }}</h3>
            <div class="van-multi-ellipsis--l3 introduction">
              {{ item.introduction }}
            </div>
            <div class="time">{{ item.time }}</div>
          </div>
          <div class="picture">
            <van-image :src="item.picture" lazy-load> </van-image>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="right item">
          <div class="content">
            <h3 class="van-ellipsis title">{{ item.title }}</h3>
            <div class="van-multi-ellipsis--l3 introduction">
              {{ item.introduction }}
            </div>
            <div class="time">{{ item.time }}</div>
          </div>
          <div class="picture">
            <van-image :src="item.picture" lazy-load> </van-image>
          </div>
        </div>
      </template>
    </div>
    <van-pagination
      v-model="currentPage"
      :page-count="2"
      @change="handleChangePage"
      mode="simple"
    />
  </div>
</template>

<script>
export default {
  name: 'CaseItem',
  data() {
    return {
      caseList: [
        {
          id: 1,
          title: '1智囊团432423分享 | 如何不花钱…',
          introduction:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家…',
          time: '2020-19-21',
          picture: require('@/assets/images/case/case-cover.png')
        },
        {
          id: 2,
          title: '2智囊团432423分享 | 如何不花钱…',
          introduction:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家…',
          time: '2020-19-21',
          picture: require('@/assets/images/case/case-cover.png')
        },
        {
          id: 3,
          title: '3智囊团432423分享 | 如何不花钱…',
          introduction:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家…',
          time: '2020-19-21',
          picture: require('@/assets/images/case/case-cover.png')
        },
        {
          id: 4,
          title: '4智囊团432423分享 | 如何不花钱…',
          introduction:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家…',
          time: '2020-19-21',
          picture: require('@/assets/images/case/case-cover.png')
        },
        {
          id: 5,
          title: '5智囊团432423分享 | 如何不花钱…',
          introduction:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家…',
          time: '2020-19-21',
          picture: require('@/assets/images/case/case-cover.png')
        },
        {
          id: 6,
          title: '6智囊团432423分享 | 如何不花钱…',
          introduction:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家…',
          time: '2020-19-21',
          picture: require('@/assets/images/case/case-cover.png')
        },
        {
          id: 7,
          title: '7智囊团432423分享 | 如何不花钱…',
          introduction:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家…',
          time: '2020-19-21',
          picture: require('@/assets/images/case/case-cover.png')
        },
        {
          id: 8,
          title: '8智囊团432423分享 | 如何不花钱…',
          introduction:
            '我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家…',
          time: '2020-19-21',
          picture: require('@/assets/images/case/case-cover.png')
        }
      ],
      pageSize: 6,
      currentPage: 1
    }
  },
  methods: {
    handleChangePage(event) {
      console.log(event)
    }
  }
}
</script>

<style lang="less" scoped>
.case-item-container {
  padding-top: 14px;
  color: #fff;
}
.item {
  margin-top: 58px;
  position: relative;
  .content {
    width: 355px;
    height: 103px;
    background: #010343;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.14);
    overflow: hidden;
    .title {
      width: 355px;
      height: 22px;
      background: #1651dd;
      margin-top: 11px;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
    .introduction {
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      margin-top: 10px;
    }
    .time {
      font-size: 8px;
      font-weight: 500;
      line-height: 11px;
      margin-top: 2px;
    }
  }
  .picture {
    position: absolute;
    top: -43px;
    height: 134px;
    width: 100px;
  }
}
.left {
  margin-left: 20px;
  .content {
    border-top-left-radius: 52px;
    border-bottom-left-radius: 52px;
  }
  .title {
    padding-left: 168px;
    padding-right: 20px;
  }
  .introduction {
    padding-left: 168px;
    padding-right: 20px;
    margin-top: 10px;
  }
  .time {
    padding-left: 279px;
  }
  .picture {
    left: 50px;
  }
}
.right {
  margin-right: 20px;
  .content {
    border-top-right-radius: 52px;
    border-bottom-right-radius: 52px;
  }
  .title {
    padding-left: 20px;
    padding-right: 168px;
  }
  .introduction {
    padding-left: 20px;
    padding-right: 168px;
  }
  .time {
    padding-left: 130px;
  }
  .picture {
    left: 205px;
  }
}

::v-deep .van-pagination {
  margin-top: 19px;
  .van-pagination__item {
    background-color: #010343 !important;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
  }
}
</style>
